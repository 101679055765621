import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9010 FHD and IN-9020 FHD Precision of Save Positions Deteriorates",
  "path": "/Frequently_Asked_Question/9020_precision_for_saved_postions/",
  "dateChanged": "2021-05-14",
  "author": "Mike Polinowski",
  "excerpt": "I have an Instar9020s from you and have the problem that positions that I save are not approached correctly after a while. The positions are completely different from the ones that were saved. What could be the reason for this?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-9010 FHD and IN-9020 FHD Precision of Save Positions Deteriorates' dateChanged='2021-05-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='Ich habe von Ihnen eine Instar9020 und habe das Problem, dass Positionen, die ich einspeichere, nicht wieder korrekt angefahren werden. Es werden ganz andere Positionen angefahren, als abgespeichert wurden. Woran kann das liegen?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/9020_precision_for_saved_postions/' locationFR='/fr/Frequently_Asked_Question/9020_precision_for_saved_postions/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <SEOHelmet title='IN-9010 FHD and IN-9020 FHD Precision of Save Positions Deteriorates' dateChanged='2021-05-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='I have an Instar9020s from you and have the problem that positions that I save are not approached correctly after a while. The positions are completely different from the ones that were saved. What could be the reason for this?' image='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/9020_precision_for_saved_postions/' locationFR='/fr/Frequently_Asked_Question/9020_precision_for_saved_postions/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "in-9010-fhd-and-in-9020-fhd-precision-of-save-positions-deteriorates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-9010-fhd-and-in-9020-fhd-precision-of-save-positions-deteriorates",
        "aria-label": "in 9010 fhd and in 9020 fhd precision of save positions deteriorates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9010 FHD and IN-9020 FHD Precision of Save Positions Deteriorates`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Hello, I have an Instar9020s from you and have the problem that positions that I save are not approached correctly after a while. The positions are completely different from the ones that were saved. What could be the reason for this?`}</p>
    <p>{`I had tried everything on the software side and could not find any error. The network existed. Cables were all correctly crimped, a different network cable brought no improvement. Router, switch checked.`}</p>
    <p>{`Power supply: All cameras ran without problems. Picture was there etc. BUT! I still measured the power supply and the power supply of the power supply unit in the distribution cabinet was not at 12v. The camera was only receiving 10V. That was the crucial problem!`}</p>
    <p>{`Even though the camera was in normal operation and ALL functions including normal control were given, it was apparently due to this voltage difference.`}</p>
    <p>{`I readjusted the power supply and the error is gone.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      